import { ACTION } from '../../constants/actionTypes';

const provinces = (state, { payload, type }) => {
  switch (type) {
    case ACTION.PROVINCES.LOAD.REQUEST: {
      return {
        ...state,
        provinces: {
          ...state.provinces,
          loading: true,
        },
      };
    }

    case ACTION.PROVINCES.LOAD.SUCCESS: {
      return {
        ...state,
        provinces: {
          ...state.provinces,
          loading: false,
          data: payload,
        },
      };
    }
    case ACTION.PROVINCES.LOAD.ERROR: {
      return {
        ...state,
        provinces: {
          ...state.provinces,
          loading: false,
          error: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default provinces;
