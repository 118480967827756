import styled from 'styled-components';
import { device } from '../../utils/breakpoints';

export const MapContainer = styled.div`
  width: 100%;
  height: 500px;
  flex: 1;
  background-color: ${({ theme }) => theme.gray};
  margin-left: auto;
  @media ${device.tablet} {
    width: 100%;
    height: 100%;
  }
`;
