import styled from 'styled-components';

export const List = styled.div`
  flex: 1;
  overflow: auto;
`;

export const NoResults = styled.p`
  padding: 50px 0;
  text-align: center;
  font-family: ${({ theme }) => theme.fontBody};
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  color: ${({ theme }) => theme.darkText};
`;
