import React, { useCallback, useContext } from 'react';
import ImgLogo from '../../assets/images/logo-agencia.png';
import ImgHappy from '../../assets/images/happy.png';
import ImgClose from '../../assets/images/close.png';

import PropTypes from 'prop-types';

import { Form } from './components/Form';
import { GlobalContext } from '../../context';
import { cleanForm } from '../../context/actions';

import {
  Container,
  Backdrop,
  Content,
  Title,
  Subtitle,
  Text,
  Logo,
  Details,
  Grid,
  Message,
  MessageImg,
  MessageTitle,
  MessageSubtitle,
  CloseButton,
} from './styles.js';

export const AgencyModal = ({
  isVisible = false,
  handleCloseModal = () => {},
  ...props
}) => {
  const { aplicacion, casaCentral, nombre, provincia, localidad, direccion, email, telefono } = props;
  const { formState, formDispatch } = useContext(GlobalContext);
  const {
    form: { success },
  } = formState;
  const onCloseModal = useCallback(() => {
    cleanForm()(formDispatch);
    handleCloseModal();
  }, [formDispatch]);

  return (
    <Container visibility={isVisible}>
      <Backdrop onClick={() => onCloseModal()} />
      <Content>
        <CloseButton onClick={() => onCloseModal()}>
          <img src={ImgClose} alt={'Cerrar'} />
        </CloseButton>
        {!success ? (
          <Grid>
            <Details>
              <Title>{casaCentral ? aplicacion?.nombreFantasia : nombre}</Title>
              <Subtitle>
                {provincia?.nombre}, {localidad?.nombre}
              </Subtitle>
              <Text>{direccion}</Text>
              <Text>{email}</Text>
              <Text>{telefono}</Text>
              <Logo>
                <img src={ImgLogo} alt={''} />
              </Logo>
            </Details>
            <Form emailAgencia={email} onCloseModal={onCloseModal} />
          </Grid>
        ) : (
          <Message>
            <MessageImg>
              <img src={ImgHappy} alt={''} />
            </MessageImg>
            <MessageTitle>Consulta enviada con éxito</MessageTitle>
            <MessageSubtitle>
              Muy pronto Tu Agencia EVT <br />
              se contactará contigo
            </MessageSubtitle>
          </Message>
        )}
      </Content>
    </Container>
  );
};

AgencyModal.propTypes = {
  casaCentral: PropTypes.bool.isRequired,
  nombre: PropTypes.string.isRequired,
  direccion: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  telefono: PropTypes.string.isRequired,
  provincia: PropTypes.object.isRequired,
  localidad: PropTypes.object.isRequired,
  aplicacion: PropTypes.object.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};
