import React, { useContext, useState, useEffect } from 'react';

import Map from '../../containers/Map';
import Filters from '../../containers/Filters';
import { AgencyModal } from '../../components';
import { changeModalVisibility } from '../../context/actions';
import { GlobalContext } from '../../context';

import { Container } from './styles.js';

const MapAgencies = () => {
  const { appState, appDispatch } = useContext(GlobalContext);
  const { currentAgency, modalVisibility, selectedTab } = appState;
  const [isMobile, setIsMobile] = useState(false);

  const handleCloseModal = () => {
    changeModalVisibility(false)(appDispatch);
  };

  const updateDimensions = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <>
      <Container>
        {isMobile ? (
          <>
            <Filters />
            {selectedTab === 'map' && isMobile && <Map />}
          </>
        ) : (
          <>
            <Filters />
            <Map />
          </>
        )}
      </Container>
      {modalVisibility && (
        <AgencyModal
          {...currentAgency}
          isVisible={modalVisibility}
          handleCloseModal={handleCloseModal}
        />
      )}
    </>
  );
};

export default MapAgencies;
