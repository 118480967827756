import React from 'react';
import PropTypes from 'prop-types';
import { Select, Option } from './styles.js';

export const Dropdown = ({
  options = [],
  selected = 0,
  placeholder = '',
  isDisabled = false,
  handleChange = () => {},
}) => {
  return (
    <Select
      disabled={isDisabled}
      value={selected ? selected : placeholder}
      onChange={(e) => handleChange(e)}
    >
      {placeholder && <Option disabled>{placeholder}</Option>}
      {options.length > 0 &&
        options.map((item, index) => (
          <Option key={index} value={item.id}>
            {item.nombre}
          </Option>
        ))}
    </Select>
  );
};

Dropdown.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.number,
  placeholder: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  isDisabled: PropTypes.bool,
};
