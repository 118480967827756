import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import app from './reducers/app';
import provinces from './reducers/provinces';
import locations from './reducers/locations';
import agencies from './reducers/agencies';
import form from './reducers/form';

import appInitialState from './initialStates/appInitialState';
import provincesInitialState from './initialStates/provincesInitialState';
import locationsInitialState from './initialStates/locationsInitialState';
import agenciesInitialState from './initialStates/agenciesInitialState';
import formInitialState from './initialStates/formInitialState';

export const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
  const [appState, appDispatch] = useReducer(app, appInitialState);
  const [provincesState, provincesDispatch] = useReducer(
    provinces,
    provincesInitialState
  );
  const [locationsState, locationsDispatch] = useReducer(
    locations,
    locationsInitialState
  );
  const [agenciesState, agenciesDispatch] = useReducer(
    agencies,
    agenciesInitialState
  );
  const [formState, formDispatch] = useReducer(
    form,
    formInitialState
  );

  return (
    <GlobalContext.Provider
      value={{
        appState,
        appDispatch,
        provincesState,
        provincesDispatch,
        locationsState,
        locationsDispatch,
        agenciesState,
        agenciesDispatch,
        formState,
        formDispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
