import React, { useCallback, useContext, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import SimpleReactValidator from 'simple-react-validator'

import { sendForm, saveConsultation } from '../../../../context/actions/index.js'
import { GlobalContext } from '../../../../context/index.js'

import { FormWrapper, Input, Error, Row, Textarea, Checkbox, ButtonsWrapper, ButtonSubmit, ButtonAlt } from './styles.js'

export const Form = ({ emailAgencia = '', onCloseModal = () => {} }) => {
  const { formState, formDispatch, appState } = useContext(GlobalContext)
  const {
    form: { loading },
  } = formState
  const { currentAgency } = appState

  SimpleReactValidator.addLocale('es', {
    accepted: 'Debes acepter los términos y condiciones.',
    email: 'El correo electrónico ingresado es inválido',
    phone: 'El número de teléfono ingresado es inválido.',
    required: 'Campo obligatorio.',
  })

  const validator = new SimpleReactValidator({ locale: 'es' })
  const simpleValidator = useRef(validator)

  const [form, setForm] = useState({
    nombre: '',
    email: '',
    emailAgencia: emailAgencia,
    telefono: '',
    consulta: '',
    apellido: '',
  })
  const [, forceUpdate] = useState()
  const [terms, setTerms] = useState(false)
  const formRef = React.createRef()

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      const formValid = simpleValidator.current.allValid()
      if (!formValid) {
        simpleValidator.current.showMessages(true)
        forceUpdate(1)
      } else {
        const consultaMapaRequest = {
          destino: '-',
          totalDias: 0,
          totalAdultos: 0,
          totalNinos: 0,
          mes: 0,
          year: 0,
          nombre: form.nombre,
          apellido: form.apellido,
          email: form.email,
          telefono: form.telefono,
          agenciaId: currentAgency.aplicacion.id,
          nombreAgencia: currentAgency.aplicacion.nombreFantasia,
          paisId: currentAgency.aplicacion.paisId,
          comentarios: form.consulta,
          tipoConsulta: 'mapa',
          emailAgencia: form.emailAgencia,
          estado: {
            id: 1,
          },
        }
        const formRequest = {
          email: form.email,
          emailAgencia: form.emailAgencia,
          nombre: form.nombre,
          telefono: form.telefono,
          consulta: form.consulta,
        }
        saveConsultation(consultaMapaRequest)
        sendForm(formRequest)(formDispatch)
      }
    },
    [form]
  )

  const handleNameChange = (e) => {
    setForm({ ...form, nombre: e.target.value })
  }

  const handleSurnameChange = (e) => {
    setForm({ ...form, apellido: e.target.value })
  }

  const handleEmailChange = (e) => {
    setForm({ ...form, email: e.target.value })
  }

  const handlePhoneChange = (e) => {
    setForm({ ...form, telefono: e.target.value })
  }

  const handleMessageChange = (e) => {
    setForm({ ...form, consulta: e.target.value })
  }

  return (
    <FormWrapper ref={formRef}>
      <Row>
        <Input name='name' type='text' required onChange={handleNameChange} value={form.nombre} placeholder='Nombre' />
        <Error>{simpleValidator.current.message('name', form.nombre, 'required')}</Error>
      </Row>
      <Row>
        <Input name='surname' type='text' required onChange={handleSurnameChange} value={form.apellido} placeholder='Apellido' />
        <Error>{simpleValidator.current.message('surname', form.apellido, 'required')}</Error>
      </Row>
      <Row>
        <Input name='email' type='email' required onChange={handleEmailChange} value={form.email} placeholder='E-mail' />
        <Error>{simpleValidator.current.message('email', form.email, 'required|email')}</Error>
      </Row>
      <Row>
        <Input name='phone' type='phone' onChange={handlePhoneChange} value={form.telefono} placeholder='Teléfono' />
      </Row>
      <Row>
        <Textarea name='message' required onChange={handleMessageChange} value={form.consulta} placeholder='Escriba aquí su consulta'></Textarea>
        <Error>{simpleValidator.current.message('message', form.consulta, 'required')}</Error>
      </Row>
      <Row>
        <Checkbox>
          <input name='terms' defaultChecked={terms} onChange={() => setTerms(!terms)} type='checkbox' required />
          He leído y acepto los <a href='#'>términos y condiciones</a> de uso
        </Checkbox>
        <Error>{simpleValidator.current.message('terms', terms, 'accepted')}</Error>
      </Row>
      <ButtonsWrapper>
        <ButtonSubmit type='button' onClick={handleSubmit}>
          {loading ? 'Cargando' : 'Enviar Consulta'}
        </ButtonSubmit>
        <ButtonAlt type='button' onClick={onCloseModal}>
          Contactar otra Agencia
        </ButtonAlt>
      </ButtonsWrapper>
    </FormWrapper>
  )
}

Form.propTypes = {
  emailAgencia: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
}
