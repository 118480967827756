/** @format */

import { createActionConst } from '../../utils/createActionConst.js';

export const ACTION = createActionConst('ACTION', {
  APP: {
    MAP_CENTER: {},
    MAP_ZOOM: {},
    CURRENT_PROVINCE: {},
    CURRENT_LOCATION: {},
    CURRENT_AGENCY: {},
    MODAL_VISIBILITY: {},
    CURRENT_TAB: {},
  },
  PROVINCES: {
    LOAD: {},
  },
  LOCATIONS: {
    LOAD: {},
  },
  AGENCIES: {
    LOAD: {},
  },
  FORM: {
    SEND: {},
    CLEAN: {},
  },
});
