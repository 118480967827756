import styled from 'styled-components';
import { device } from '../../utils/breakpoints';

export const Container = styled.div`
  justify-content: space-between;
  background-color: ${({ theme }) => theme.gray};
  overflow: hidden;
  position: relative;
  @media ${device.tablet} {
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 5px;
    display: flex;
    margin: 50px auto;
    height: 720px;
  }
  @media ${device.desktop} {
    max-width: 1290px;
  }
`;
