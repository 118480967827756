import { ACTION } from '../../constants/actionTypes';

const form = (state, { type }) => {
  switch (type) {
    case ACTION.FORM.SEND.REQUEST: {
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        },
      };
    }

    case ACTION.FORM.SEND.SUCCESS: {
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
          success: true,
          error: false,
        },
      };
    }
    case ACTION.FORM.SEND.ERROR: {
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
          success: false,
          error: true,
        },
      };
    }

    case ACTION.FORM.CLEAN.REQUEST: {
      return {
        ...state,
        form: {
          loading: false,
          success: false,
          error: false,
        },
      };
    }

    default:
      return state;
  }
};

export default form;
