import React from 'react';
import { ThemeProvider } from 'styled-components';

import { GlobalProvider } from './context';
import MapAgencies from './pages/MapAgencies';
import { Globalstyle, mainTheme } from './theme/globalStyle';

function App() {
  return (
    <GlobalProvider>
      <Globalstyle />
      <ThemeProvider theme={mainTheme}>
        <MapAgencies />
      </ThemeProvider>
    </GlobalProvider>
  );
}

export default App;
