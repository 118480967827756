import styled from 'styled-components';
import { device } from '../../utils/breakpoints';

export const Container = styled.div`
  padding: 16px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  display: flex;
  opacity: ${(props) => (props.visibility ? '1' : '0')};
  visibility: ${(props) => (props.visibility ? 'visible' : 'hidden')};
  @media ${device.tablet} {
    padding: 50px;
  }
`;

export const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(36, 36, 36, 0.25);
`;

export const Content = styled.div`
  position: relative;
  z-index: 10;
  max-width: 425px;
  width: 100%;
  background-color: ${({ theme }) => theme.gray};
  box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 25px;
  overflow: auto;
  max-height: 100%;
  @media ${device.tablet} {
    padding: 55px 25px 25px;
    max-width: 768px;
  }
  @media ${device.desktop} {
    padding: 55px;
    max-width: 1076px;
  }
`;

export const Grid = styled.div`
  @media ${device.tablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
`;

export const Message = styled.div`
  text-align: center;
  padding: 55px 0;
`;

export const MessageTitle = styled.h2`
  font-family: ${({ theme }) => theme.fontBody};
  color: ${({ theme }) => theme.primary};
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  margin: 10px;
`;

export const MessageSubtitle = styled.h3`
  font-family: ${({ theme }) => theme.fontBody};
  color: ${({ theme }) => theme.primary};
  font-weight: 400;
  font-size: 25px;
  line-height: 34px;
`;

export const MessageImg = styled.div`
  margin-bottom: 20px;
`;

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontBody};
  font-weight: 700;
  font-size: 20px;
  line-height: 135%;
  margin: 0;
  color: #363636;
  @media ${device.tablet} {
    font-size: 26px;
  }
`;

export const Subtitle = styled.h3`
  font-family: ${({ theme }) => theme.fontBody};
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  margin-bottom: 10px;
  color: #363636;
  @media ${device.tablet} {
    font-size: 23px;
    margin-bottom: 20px;
  }
`;

export const Text = styled.p`
  font-family: ${({ theme }) => theme.fontBody};
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  color: ${({ theme }) => theme.darkText};
  color: #363636;
  @media ${device.tablet} {
    font-size: 22px;
  }
`;

export const Logo = styled.div`
  display: none;
  @media ${device.tablet} {
    display: block;
    background-color: ${({ theme }) => theme.white};
    padding: 15px 20px;
    border-radius: 4px;
    margin-top: 20px;
  }
  @media ${device.desktop} {
    padding: 35px 50px;
  }
  & img {
    max-width: 100%;
  }
`;

export const Details = styled.div`
  margin-bottom: 20px;
  @media ${device.tablet} {
    margin-bottom: 0;
  }
`;

export const Form = styled.div`
  background-color: blue;
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 23px;
  width: 23px;
  height: 23px;
  background-color: #ebebeb;
  position: absolute;
  top: 18px;
  right: 18px;
`;
