import React from 'react';
import PropTypes from 'prop-types';

import MarkerIcon from '../../assets/images/marker.png';
import { MarkerContainer, MarkerImg } from './styles.js';

export const Marker = ({ item = {}, handlePress = () => {} }) => {
  return (
    <MarkerContainer onClick={handlePress}>
      <MarkerImg src={MarkerIcon} alt={item?.name} />
    </MarkerContainer>
  );
};

Marker.propTypes = {
  item: PropTypes.object.isRequired,
  handlePress: PropTypes.func.isRequired,
};
