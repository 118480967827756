import styled from 'styled-components';

export const Select = styled.select`
  height: 40px;
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.border};
  padding: 0 15px;
  font-family: ${({ theme }) => theme.fontBody};
  font-weight: 500;
  font-size: 16px;
  border-radius: 2px;
  :disabled {
    opacity: 0.5;
  }
`;

export const Option = styled.option`
  font-family: ${({ theme }) => theme.fontBody};
  font-weight: 500;
  font-size: 16px;
`;
