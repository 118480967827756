import React, { useCallback, useContext, useEffect, useState } from 'react';
import BGFilters from '../../assets/images/bg-filters.png';

import { GlobalContext } from '../../context';
import {
  getLocations,
  getProvinces,
  getAgencies,
  changeCurrentProvince,
  changeMapZoom,
  changeMapCenter,
  changeCurrentLocation,
  changeSelectedTab,
} from '../../context/actions';
import { AgenciesList, Dropdown } from '../../components';

import {
  Container,
  Content,
  ImgFilters,
  Title,
  Banner,
  TabWrapper,
  Tab,
} from './styles.js';

const Filters = () => {
  const {
    appState,
    appDispatch,
    provincesDispatch,
    provincesState,
    locationsState,
    locationsDispatch,
    agenciesState,
    agenciesDispatch,
  } = useContext(GlobalContext);
  const { currentProvince, currentLocation, selectedTab } = appState;
  const { provinces } = provincesState;
  const { locations } = locationsState;
  const { agencies } = agenciesState;

  const [firstTime, setFirstTime] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [cities, setCities] = useState([]);
  const [currentAgencies, setCurrentAgencies] = useState([]);

  useEffect(() => {
    if (locations.data.length > 0) {
      setCities(locations.data);
    }
  }, [locations]);

  useEffect(() => {
    if (agencies.data.length === 0 && !agencies.loading && !agencies.error) {
      getAgencies()(agenciesDispatch);
    }
  }, [agencies]);

  useEffect(() => {
    if (agencies.data.length > 0 && firstTime) {
      const agenciesProvincesIds = agencies.data.map(
        (agency) => agency.provinciaId
      );
      getProvinces(agenciesProvincesIds)(provincesDispatch);
      setFirstTime(false);
    }
  }, [agencies]);

  useEffect(() => {
    if (provinces.data.length > 0 && currentProvince) {
      setAgenciesByProvince(currentProvince);
    }
  }, [agencies, currentProvince]);

  useEffect(() => {
    if (cities.length > 0 && currentLocation) {
      setAgenciesByCity(currentLocation);
    }
  }, [agencies, currentLocation]);

  useEffect(() => {
    if (agencies.data.length > 0) {
      agencies.data.map((agency) => {
        provinces &&
          provinces?.data?.length > 0 &&
          provinces?.data?.some((province) => {
            if (agency.provinciaId === province.id) {
              agency.provincia = province;
            }
          });
        locations &&
          locations?.data?.length > 0 &&
          locations?.data?.some((location) => {
            if (agency.localidadId === location.id) {
              agency.localidad = location;
            }
          });
      });
    }
  }, [agencies, locations, provinces]);

  const setAgenciesByProvince = (province) => {
    if (agencies?.data?.length > 0) {
      setCurrentAgencies(
        agencies?.data?.filter((agency) => agency.provinciaId == province?.id)
      );
    }
  };

  const setAgenciesByCity = (city) => {
    if (currentAgencies.length > 0) {
      setCurrentAgencies(
        currentAgencies.filter((agency) => agency.localidadId == city?.id)
      );
    }
  };

  const onProvinceChange = useCallback(
    (e) => {
      const provinceSelected = provinces?.data.find(
        (province) => province.id == e.target.value
      );
      changeCurrentProvince(provinceSelected)(appDispatch);
      changeMapZoom(7)(appDispatch);
      changeMapCenter({
        lat: provinceSelected?.latitud,
        lng: provinceSelected?.longitud,
      })(appDispatch);
      const agenciesProvincesIds = agencies.data.map(
        (agency) => agency.localidadId
      );
      getLocations(
        provinceSelected?.id,
        agenciesProvincesIds
      )(locationsDispatch);
      changeCurrentLocation('')(appDispatch);
      getAgencies(provinceSelected?.id)(agenciesDispatch);
    },
    [provinces, changeCurrentLocation, getAgencies]
  );

  const onLocationChange = useCallback(
    (e) => {
      const locationSelected = cities.find((city) => city.id == e.target.value);
      changeCurrentLocation(locationSelected)(appDispatch);
      getAgencies(currentProvince?.id, locationSelected?.id)(agenciesDispatch);
    },
    [cities, changeCurrentLocation, getAgencies]
  );

  const updateDimensions = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <Container>
      <TabWrapper>
        <Tab
          isActive={selectedTab === 'map'}
          onClick={() => changeSelectedTab('map')(appDispatch)}
        >
          Mapa
        </Tab>
        <Tab
          isActive={selectedTab === 'list'}
          onClick={() => changeSelectedTab('list')(appDispatch)}
        >
          Lista
        </Tab>
      </TabWrapper>
      <Content>
        <Dropdown
          options={provinces?.data}
          selected={currentProvince?.id}
          handleChange={(e) => onProvinceChange(e)}
          placeholder={'Seleccioná una Provincia'}
        />
        <Dropdown
          options={cities}
          isDisabled={!currentProvince}
          selected={currentLocation?.id}
          handleChange={(e) => onLocationChange(e)}
          placeholder={'Seleccioná una Localidad'}
        />
      </Content>

      {isMobile ? (
        selectedTab === 'list' && (
          <AgenciesList
            agencies={currentAgencies}
            isLoading={
              agencies?.loading || locations?.loading || provinces?.loading
            }
          />
        )
      ) : !currentProvince ? (
        <Banner>
          <Title>
            Somos la comunidad de Agencias de Viajes Independientes más fuerte
            de Latinoamérica
          </Title>
          <ImgFilters src={BGFilters} alt={''} />
        </Banner>
      ) : (
        <AgenciesList
          agencies={currentAgencies}
          isLoading={
            agencies?.loading || locations?.loading || provinces?.loading
          }
        />
      )}
    </Container>
  );
};

export default Filters;
