import axios from 'axios';
import { ACTION } from '../../../constants/actionTypes';
import { LAT_LNG_PROVINCES } from '../../../constants/provinces';

const loadProvincesRequest = async () =>
  axios({
    url: `/provincia`,
    baseURL: process.env.REACT_APP_GEO_API_URL,
    method: 'GET',
  });

export const getProvinces = (agenciesIds) => (dispatch) => {
  dispatch({
    type: ACTION.PROVINCES.LOAD.REQUEST,
  });
  loadProvincesRequest()
    .then((res) => {
      var provincesNames = {};
      res?.data?.filter((province) => {
        if (province.pais.id === 1) {
          provincesNames[province.id] = { nombre: province.nombre };
        }
      });
      const provincesLatLng = LAT_LNG_PROVINCES.map(
        (province) =>
          (provincesNames[province.id] = {
            ...provincesNames[province.id],
            id: province.id,
            latitud: province.lat,
            longitud: province.lng,
            isProvince: true,
          })
      ).filter((province) => agenciesIds.some((id) => id === province.id));

      dispatch({
        type: ACTION.PROVINCES.LOAD.SUCCESS,
        payload: provincesLatLng,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: ACTION.PROVINCES.LOAD.ERROR,
        payload: err.response ? err.response.data : 'CONNECTION_ERROR',
      });
    });
};
