import styled from 'styled-components';
import { device } from '../../utils/breakpoints';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  @media ${device.desktop} {
    max-width: 500px;
  }
`;

export const Content = styled.div`
  padding: 25px;
  border-bottom: 1px solid ${({ theme }) => theme.border};
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
`;

export const Banner = styled.div`
  background-color: ${({ theme }) => theme.white};
  margin-top: auto;
`;

export const ImgFilters = styled.img`
  max-width: 100%;
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.primary};
  font-family: ${({ theme }) => theme.fontBody};
  text-align: center;
  margin: 55px 60px 10px 60px;
  font-weight: 900;
  font-size: 22px;
  line-height: 30px;
`;

export const TabWrapper = styled.div`
  display: flex;
  @media ${device.tablet} {
    display: none;
  }
`;

export const Tab = styled.button`
  background-color: ${(props) =>
    props.isActive ? props.theme.primary : props.theme.tertiary};
  width: 100%;
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.fontBody};
  height: 40px;
  padding: 0 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
`;
