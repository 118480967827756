import React, { useContext, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';

import { GlobalContext } from '../../context';
import { Marker } from '../../components';

import { MapContainer } from './styles.js';
import {
  changeCurrentAgency,
  changeCurrentProvince,
  changeMapCenter,
  changeMapZoom,
  changeModalVisibility,
  getAgencies,
  getLocations,
} from '../../context/actions';

const Map = () => {
  const {
    appState,
    appDispatch,
    provincesState,
    agenciesState,
    agenciesDispatch,
    locationsState,
    locationsDispatch,
  } = useContext(GlobalContext);
  const [mapInstance, setMapInstance] = useState(false);
  const [markers, setMarkers] = useState(false);

  const { mapCenter, mapZoom } = appState;
  const { provinces } = provincesState;
  const { locations } = locationsState;
  const { agencies } = agenciesState;

  useEffect(() => {
    if (agencies?.data?.length > 0 && locations?.data?.length > 0) {
      setMarkers(agencies?.data);
    } else {
      setMarkers(provinces?.data);
    }
  }, [agencies, provinces, locations]);

  const onMapChange = (center, zoom) => {
    changeMapZoom(zoom)(appDispatch);
    changeMapCenter(center)(appDispatch);
  };

  const MAP_OPTIONS = {
    disableDefaultUI: true,
  };

  const handleMarkerPress = (marker) => {
    if (marker?.isProvince) {
      changeCurrentProvince(marker)(appDispatch);
      changeMapZoom(7)(appDispatch);
      changeMapCenter({
        lat: marker?.latitud,
        lng: marker?.longitud,
      })(appDispatch);
      const agenciesProvincesIds = agencies.data.map(
        (agency) => agency.localidadId
      );
      getLocations(marker?.id, agenciesProvincesIds)(locationsDispatch);
      getAgencies(marker?.id)(agenciesDispatch);
    } else {
      changeCurrentAgency(marker)(appDispatch);
      changeModalVisibility(true)(appDispatch);
    }
  };

  return (
    <MapContainer>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
        options={MAP_OPTIONS}
        center={mapCenter}
        zoom={mapZoom}
        onChange={({center, zoom}) => onMapChange(center, zoom)}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={(map) => setTimeout(() => setMapInstance(map), 200)}
      >
        {mapInstance &&
          markers &&
          markers.map(
            (marker, index) =>
              marker?.latitud &&
              marker?.longitud && (
                <Marker
                  item={marker}
                  key={index}
                  lat={marker?.latitud}
                  lng={marker?.longitud}
                  handlePress={() => handleMarkerPress(marker)}
                />
              )
          )}
      </GoogleMapReact>
    </MapContainer>
  );
};

export default Map;
