export const LAT_LNG_PROVINCES = [
  {
    id: 1, // Buenos Aires
    lat: -37.1498821,
    lng: -60.0254816,
  },
  {
    id: 2, // Capital Federal
    lat: -34.6158238,
    lng: -58.4332985,
  },
  {
    id: 3, // Gran Buenos Aires
    lat: -34.505655,
    lng: -58.4543193,
  },
  {
    id: 4, // Catamarca
    lat: -27.6439811,
    lng: -66.9381202,
  },
  {
    id: 5, // Chaco
    lat: -26.0756128,
    lng: -60.8999125,
  },
  {
    id: 6, // Chubut
    lat: -43.9779137,
    lng: -70.1285035,
  },
  {
    id: 7, // Cordoba
    lat: -31.3994342,
    lng: -64.2643841,
  },
  {
    id: 8, // Corrientes
    lat: -28.9918374,
    lng: -57.666136,
  },
  {
    id: 9, // Entre Rios
    lat: -32.0993727,
    lng: -59.2880279,
  },
  {
    id: 10, // Formosa
    lat: -24.6664944,
    lng: -59.9524189,
  },
  {
    id: 11, // Jujuy
    lat: -23.197202,
    lng: -65.6820085,
  },
  {
    id: 12, // La Pampa
    lat: -37.0689776,
    lng: -65.7297139,
  },
  {
    id: 13, // La Rioja
    lat: -29.853734,
    lng: -67.5289487,
  },
  {
    id: 14, // Mendoza
    lat: -34.7873176,
    lng: -68.5311155,
  },
  {
    id: 15, // Misiones
    lat: -26.8299549,
    lng: -54.8480592,
  },
  {
    id: 16, // Neuquen
    lat: -38.6051647,
    lng: -69.9818719,
  },
  {
    id: 17, // Rio Negro
    lat: -39.7890631,
    lng: -67.3506823,
  },
  {
    id: 18, // Salta
    lat: -24.2077863,
    lng: -65.4548863,
  },
  {
    id: 19, // San Juan
    lat: -30.5075849,
    lng: -68.6155398,
  },
  {
    id: 20, // San Luis
    lat: -33.916945,
    lng: -66.1332734,
  },
  {
    id: 21, // Santa Cruz
    lat: -49.1986371,
    lng: -69.6316528,
  },
  {
    id: 22, // Santa Fe
    lat: -31.1915329,
    lng: -60.8802759,
  },
  {
    id: 23, // Santiago del Estero
    lat: -28.064481,
    lng: -63.44966,
  },
  {
    id: 24, // Tierra del Fuego
    lat: -54.5075617,
    lng: -67.2205547,
  },
  {
    id: 25, // Tucuman
    lat: -26.8328417,
    lng: -65.2926339,
  },
];
