import { ACTION } from '../../constants/actionTypes';

export const app = (state, { payload, type }) => {
  switch (type) {
    case ACTION.APP.MAP_CENTER.REQUEST:
      return { ...state, mapCenter: payload };
    case ACTION.APP.MAP_ZOOM.REQUEST:
      return { ...state, mapZoom: payload };
    case ACTION.APP.CURRENT_PROVINCE.REQUEST:
      return { ...state, currentProvince: payload };
    case ACTION.APP.CURRENT_LOCATION.REQUEST:
      return { ...state, currentLocation: payload };
    case ACTION.APP.CURRENT_AGENCY.REQUEST:
      return { ...state, currentAgency: payload };
    case ACTION.APP.MODAL_VISIBILITY.REQUEST:
      return { ...state, modalVisibility: payload };
    case ACTION.APP.CURRENT_TAB.REQUEST:
      return { ...state, selectedTab: payload };
    default:
      return state;
  }
};

export default app;
