import styled, { css } from 'styled-components';
import { device } from '../../../../utils/breakpoints';

const inputCommonStyles = ({ theme }) => css`
  color: #363636;
  font-family: ${theme.fontBody};
  background-color: ${theme.white};
  border: 1px solid ${theme.border};
  border-radius: 4px;
  height: 37px;
  padding: 0 15px;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  @media ${device.tablet} {
    padding: 0 20px;
  }
`;

const buttonCommonStyles = ({ theme }) => css`
  border-radius: 2px;
  color: ${theme.white};
  font-family: ${theme.fontBody};
  background-color: ${theme.white};
  height: 40px;
  padding: 0 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
`;

export const FormWrapper = styled.form`
  display: grid;
  gap: 10px;
  @media ${device.tablet} {
    gap: 15px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  gap: 15px;
  @media ${device.desktop} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Input = styled.input`
  ${inputCommonStyles};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Error = styled.p`
  color: red;
  margin-top: 5px;
  font-family: ${({ theme }) => theme.fontBody};
  font-size: 12px;
`;

export const Textarea = styled.textarea`
  ${inputCommonStyles};
  padding: 15px;
  min-height: 110px;
  resize: vertical;
  @media ${device.tablet} {
    min-height: 140px;
    padding: 20px;
  }
`;

export const Checkbox = styled.label`
  color: ${({ theme }) => theme.textDark};
  font-family: ${({ theme }) => theme.fontBody};
  font-size: 14px;
  @media ${device.tablet} {
    font-size: 16px;
  }

  input {
    margin-right: 8px;
  }
  a {
    color: ${({ theme }) => theme.link};
    text-decoration: none;
  }
`;

export const ButtonSubmit = styled.button`
  ${buttonCommonStyles};
  background-color: ${({ theme }) => theme.primary};
`;

export const ButtonAlt = styled.button`
  ${buttonCommonStyles};
  background-color: ${({ theme }) => theme.tertiary};
`;
