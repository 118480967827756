import styled from 'styled-components';

export const MarkerContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

export const MarkerImg = styled.img`
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -16px;
`;
