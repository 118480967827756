import axios from 'axios';
import { ACTION } from '../../../constants/actionTypes';

const sendFormRequest = async (formData) =>
  axios({
    url: `/contacto`,
    baseURL: process.env.REACT_APP_MARCA_BLANCA,
    data: formData,
    method: 'POST',
  });

export const sendForm = (formData) => (dispatch) => {
  dispatch({
    type: ACTION.FORM.SEND.REQUEST,
  });
  sendFormRequest(formData)
    .then((res) => {
      if (res.status !== 201) {
        throw new Error();
      }
      dispatch({ type: ACTION.FORM.SEND.SUCCESS });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: ACTION.FORM.SEND.ERROR });
    });
};

export const cleanForm = () => (dispatch) => {
  dispatch({
    type: ACTION.FORM.CLEAN.REQUEST,
  });
};

export const saveConsultation = async (consultaMapaRequest) => {
  return axios.post(`${process.env.REACT_APP_MARCA_BLANCA}/consultas`, consultaMapaRequest, { 
      headers: {
        'Content-type': 'application/json',
        'Accept': '*/*',
      },
    },
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}
