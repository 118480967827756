import axios from 'axios';
import { ACTION } from '../../../constants/actionTypes';


const loadAgenciesRequest = async () => {
  const searchParams = new URLSearchParams(document.location.search)
  const applicationId = searchParams.get('applicationId')
  return axios({
    url: `/aplicaciones-sucursales${applicationId ? `/aplicacion?page=1&maxPerPage=1000&aplicacionId=${applicationId}` : ''}`,
    baseURL: process.env.REACT_APP_APPLICATIONS_API,
    method: 'GET',
  });
}

export const getAgencies = (provinciaId, localidadId) => (dispatch) => {
  dispatch({
    type: ACTION.AGENCIES.LOAD.REQUEST,
  });
  loadAgenciesRequest()
    .then((res) => {
      console.log('mariano',res)
      let agencies;
      if (provinciaId && localidadId) {
        agencies = res?.data?.data.filter(
          (agency) =>
            agency.provinciaId == provinciaId &&
            agency.localidadId == localidadId
        );
      } else if (provinciaId && !localidadId) {
        agencies = res?.data?.data.filter(
          (agency) => agency.provinciaId == provinciaId
        );
      } else {
        agencies = res?.data.data;
      }
      dispatch({
        type: ACTION.AGENCIES.LOAD.SUCCESS,
        payload: agencies,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: ACTION.AGENCIES.LOAD.ERROR,
        payload: err.response ? err.response.data.message : 'CONNECTION_ERROR',
      });
    });
};
