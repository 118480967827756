import { ACTION } from '../../constants/actionTypes';

const agencies = (state, { payload, type }) => {
  switch (type) {
    case ACTION.AGENCIES.LOAD.REQUEST: {
      return {
        ...state,
        agencies: {
          ...state.agencies,
          loading: true,
        },
      };
    }

    case ACTION.AGENCIES.LOAD.SUCCESS: {
      return {
        ...state,
        agencies: {
          ...state.agencies,
          loading: false,
          data: payload,
        },
      };
    }
    case ACTION.AGENCIES.LOAD.ERROR: {
      return {
        ...state,
        agencies: {
          ...state.agencies,
          loading: false,
          error: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default agencies;
