export default {
  mapCenter: {
    lat: -38.45155,
    lng: -63.5989205,
  },
  mapZoom: 4,
  currentProvince: '',
  currentLocation: '',
  currentAgency: '',
  selectedTab: 'map',
  modalVisibility: false,
};
