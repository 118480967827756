import { createGlobalStyle } from 'styled-components';

export const mainTheme = {
  primary: '#254290',
  secondary: '#8D278B',
  tertiary: '#26A9E0',
  link: '#5798F4',
  white: '#FFFFFF',
  black: '#000000',
  gray: '#F3F3F3',
  border: '#CACACA',
  text: '#ADADAD',
  darkText: '#363636',
  fontBody: 'Nunito, sans-serif',
};

export const Globalstyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  button, input[type="submit"], input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
  img {
    vertical-align: middle;
  }
`;
