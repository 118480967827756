import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AgencyCard } from '../../components';
import {
  changeCurrentAgency,
  changeModalVisibility,
} from '../../context/actions';
import { GlobalContext } from '../../context';
import { List, NoResults } from './styles.js';

export const AgenciesList = ({ agencies = [], isLoading = false }) => {
  const { appDispatch } = useContext(GlobalContext);

  const handleShowModal = (agency) => {
    changeCurrentAgency(agency)(appDispatch);
    changeModalVisibility(true)(appDispatch);
  };

  if (isLoading) {
    return;
  }
  if (agencies.length === 0) {
    return <NoResults>No hay resultados</NoResults>;
  }
  return (
    <>
      <List>
        {agencies.map((item, index) => {
          return (
            <AgencyCard
              key={index}
              agency={item}
              handleShowModal={handleShowModal}
            />
          );
        })}
      </List>
    </>
  );
};

AgenciesList.propTypes = {
  agencies: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
