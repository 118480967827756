import { ACTION } from '../../../constants/actionTypes';

export const changeCurrentProvince = (currentProvince) => (dispatch) => {
  dispatch({
    type: ACTION.APP.CURRENT_PROVINCE.REQUEST,
    payload: currentProvince,
  });
};

export const changeCurrentLocation = (currentLocation) => (dispatch) => {
  dispatch({
    type: ACTION.APP.CURRENT_LOCATION.REQUEST,
    payload: currentLocation,
  });
};

export const changeCurrentAgency = (currentAgency) => (dispatch) => {
  dispatch({
    type: ACTION.APP.CURRENT_AGENCY.REQUEST,
    payload: currentAgency,
  });
};

export const changeMapZoom = (mapZoom) => (dispatch) => {
  dispatch({
    type: ACTION.APP.MAP_ZOOM.REQUEST,
    payload: mapZoom,
  });
};

export const changeMapCenter = (changeMapCenter) => (dispatch) => {
  dispatch({
    type: ACTION.APP.MAP_CENTER.REQUEST,
    payload: changeMapCenter,
  });
};

export const changeModalVisibility = (visibility) => (dispatch) => {
  dispatch({
    type: ACTION.APP.MODAL_VISIBILITY.REQUEST,
    payload: visibility,
  });
};

export const changeSelectedTab = (selectedTab) => (dispatch) => {
  dispatch({
    type: ACTION.APP.CURRENT_TAB.REQUEST,
    payload: selectedTab,
  });
};
