import styled from 'styled-components';

export const Container = styled.div`
  padding: 25px 25px 15px 25px;
  border-bottom: 1px solid ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.white};
`;

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontBody};
  font-weight: 700;
  font-size: 19px;
  line-height: 26px;
  margin: 0;
`;

export const Subtitle = styled.h3`
  font-family: ${({ theme }) => theme.fontBody};
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
`;

export const Button = styled.button`
  font-family: ${({ theme }) => theme.fontBody};
  color: ${({ theme }) => theme.link};
  font-size: 15px;
  line-height: 20px;
  margin-top: 15px;
`;
