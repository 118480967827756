import axios from 'axios'
import { ACTION } from '../../../constants/actionTypes'

const loadLocationsRequest = async () =>
  axios({
    url: `/localidad`,
    baseURL: process.env.REACT_APP_GEO_API_URL,
    method: 'GET',
  })

export const getLocations = (provinciaId = null, agenciesIds = []) => (dispatch) => {
  dispatch({
    type: ACTION.LOCATIONS.LOAD.REQUEST,
  })
  loadLocationsRequest()
    .then((res) => {
      const locationsByProvince = res?.data.filter((location) => location.provincia.id == provinciaId)
      const locationsByAgencies = locationsByProvince.filter((location) => agenciesIds.some((id) => id === location.id))
      dispatch({
        type: ACTION.LOCATIONS.LOAD.SUCCESS,
        payload: locationsByAgencies,
      })
    })
    .catch((err) => {
      console.log(err)
      dispatch({
        type: ACTION.LOCATIONS.LOAD.ERROR,
        payload: err.response ? err.response.data : 'CONNECTION_ERROR',
      })
    })
}
