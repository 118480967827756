import React from 'react';
import PropTypes from 'prop-types';

import { Container, Title, Subtitle, Button } from './styles.js';

export const AgencyCard = ({ agency = {}, handleShowModal = () => {} }) => {
  const { casaCentral, nombre, aplicacion, provincia, localidad, direccion } = agency;
  return (
    <>
      <Container>
        <Title>{casaCentral ? aplicacion?.nombreFantasia : nombre}</Title>
        <Subtitle>{provincia?.nombre}, {localidad?.nombre}</Subtitle>
        <Subtitle>{direccion}</Subtitle>
        <Button onClick={() => handleShowModal(agency)}>Contactar</Button>
      </Container>
    </>
  );
};

AgencyCard.propTypes = {
  agency: PropTypes.object.isRequired,
  handleShowModal: PropTypes.func.isRequired,
};
